import { MbText } from 'mintbase-ui'

const CenteredHeader = ({ header, subHeader }) => (
  <div className="grid gap-24 text-center mt-64">
    <MbText className="h1-90 dark:text-white text-gray-800">{header}</MbText>
    <MbText className="p-sub-90 mb-24 dark:text-gray-200 text-gray-800">
      {subHeader}
    </MbText>
  </div>
)

export default CenteredHeader
