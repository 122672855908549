import { useQuery } from '@apollo/client'
import { useEffect } from 'react'
import { GET_MAIN_STATS } from './MainStats.graphql'
import { useAppContext, AppContextActions as actions } from 'context/AppContext'

const useStats = () => {
  const {
    dispatch,
    state: { user },
  } = useAppContext()

  const { data, loading } = useQuery(GET_MAIN_STATS)

  const parseYactoToNear = (price: number) => {
    return price / 1e24
  }

  return {
    loading,
    overallStats: [
      {
        stat: data?.stores_aggregate.aggregate?.count.toLocaleString(),
        name: 'Stores',
      },
      {
        stat: `${parseYactoToNear(data?.offers_aggregate.aggregate?.sum?.price)
          .toFixed(1)
          .toLocaleString()} N`,
        name: 'Total Earned',
      },
      {
        stat: data?.tokens_aggregate.aggregate?.count.toLocaleString(),
        name: 'Tokens',
      },
      {
        stat: data?.minters_aggregate.aggregate?.count.toLocaleString(),
        name: 'Minters',
      },
      {
        stat: data?.lists_aggregate.aggregate?.count.toLocaleString(),
        name: 'Listed',
      },
    ],
  }
}

export { useStats }
