import ContentCard, { ContentCardType } from '../ContentCard'
import { MbButton, ESize, EState } from 'mintbase-ui'
import Link from 'next/link'
import CenteredHeader from 'components/CenteredHeader/CenteredHeader'

export const ContentCards = ({
  header,
  subHeader,
  cards,
  buttonText,
  buttonUrl,
}: {
  header: string
  subHeader: string
  cards: ContentCardType[]
  buttonText: string
  buttonUrl: string
}) => {
  return (
    <div className="grid gap-24 text-center mt-64">
      <CenteredHeader header={header} subHeader={subHeader} />
      <div className="grid flow grid-flow-row md:grid-cols-2 xl:grid-cols-3 gap-24">
        {cards.map((card, index) => (
          <ContentCard key={`building-${index}`} {...card} />
        ))}
      </div>
      <div className={'text-center dark:text-gray-300 mb-48'}>
        {buttonUrl.charAt(0) === '/' ? (
          <Link passHref href={buttonUrl}>
            <a rel="noreferrer">
              <MbButton
                label={buttonText}
                size={ESize.MEDIUM}
                state={EState.ACTIVE}
              />
            </a>
          </Link>
        ) : (
          <Link passHref href={buttonUrl}>
            <a target="_blank" rel="noreferrer">
              <MbButton
                label={buttonText}
                size={ESize.MEDIUM}
                state={EState.ACTIVE}
              />
            </a>
          </Link>
        )}
      </div>
    </div>
  )
}

export default ContentCards
