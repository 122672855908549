import { useQuery } from '@apollo/client'
import CenteredHeader from 'components/CenteredHeader/CenteredHeader'
import Grid from 'components/Grid'
import StoreCard from 'components/StoreCard'
import {
  BIG_LANDING_ITEMS_PER_PAGE,
  SMALL_LANDING_ITEMS_PER_PAGE,
} from 'constants/pagination'
import { useItemsPerPage } from 'hooks/useItemsPerPage'
import { MbText } from 'mintbase-ui'
import { GET_TOP_STORES } from './TopStores.graphql'

const Stores = () => {
  // const [firstDayOfMonth] = new Date(
  //   new Date().getFullYear(),
  //   new Date().getMonth(),
  //   1
  // ).toISOString().split('T') // omit timezone from this query

  const { itemsPerPage } = useItemsPerPage({
    smallScreenItems: SMALL_LANDING_ITEMS_PER_PAGE,
    bigScreenItems: BIG_LANDING_ITEMS_PER_PAGE,
  })

  const { data, loading } = useQuery(GET_TOP_STORES, {
    variables: {
      // date: firstDayOfMonth,
      limit: itemsPerPage,
    },
  })

  const stores =
    loading || !data
      ? []
      : data.mb_views_top_stores.map((item) => ({
          id: item.store_id,
        }))

  return (
    <div>
      <CenteredHeader
        header="Stores are Incredibly Powerful"
        subHeader="Each is an independently owned smart contract that you can add other minters to"
      />
      <MbText className="h3-130 text-black dark:text-white">Top Stores</MbText>
      <div className="mt-32">
        <Grid>
          {stores.map((store, index) => {
            return <StoreCard key={`${index}${store.id}`} id={store.id} />
          })}
        </Grid>
      </div>
    </div>
  )
}

export default Stores
