import { DocumentNode, gql } from 'apollo-boost'

export const GET_LATEST_LIST: DocumentNode = gql`
  query getMyMarketThings(
    $limit: Int!
    $offset: Int!
    $orderBy: [markets_order_by!]
  ) {
    markets(
      where: { list: { removedAt: { _is_null: true } } }
      order_by: $orderBy
      limit: $limit
      offset: $offset
    ) {
      id
      createdAt
      createdBy
      thing {
        id
        metaId
        metadata {
          title
          description
          media
          media_hash
          animation_hash
          animation_url
          youtube_url
          document
          document_hash
          extra
          external_url
          category
          type
          visibility
          media_type
          animation_type
          tags
          media_size
          animation_size
        }
      }
      list {
        price
        groupId
        ownerId
        autotransfer
        createdAt
      }
    }
    markets_aggregate(
      where: { list: { removedAt: { _is_null: true } } }
      order_by: $orderBy
    ) {
      aggregate {
        count
      }
    }
  }
`

export const GET_LATEST_STORES = gql`
  query getLatestStores {
    store(order_by: { createdAt: desc }, limit: 24) {
      id
      name
    }
  }
`

export const GET_ACTIVE_STORES = gql`
  query getTopAuctions {
    top_stores(limit: 24) {
      store {
        name
        id
      }
    }
  }
`

export const GET_TOP_AUCTIONS = gql`
  query getTopAuctions(
    $limit: Int!
    $offset: Int!
    $orderBy: [markets_order_by!]
  ) {
    markets(
      where: { autotransfer: { _eq: false } }
      order_by: $orderBy
      limit: $limit
      offset: $offset
    ) {
      id
      createdAt
      createdBy
      thingId
      thing {
        id
        metaId
        store {
          id
        }
        metadata {
          title
          description
          media
          media_hash
          animation_hash
          animation_url
          youtube_url
          document
          document_hash
          extra
          external_url
          category
          type
          visibility
          media_type
          animation_type
          tags
          media_size
          animation_size
        }
      }
      list {
        price
        groupId
        ownerId
        autotransfer
        createdAt
        token {
          splits {
            id
            account
            txId
          }
          royaltys {
            id
            account
            txId
          }
        }
      }
    }
    markets_aggregate(where: { autotransfer: { _eq: false } }) {
      aggregate {
        count
      }
    }
  }
`
