import ExternalButton from 'components/ExternalButton'
import StoreCard from 'components/StoreCard'
import { MainStats } from 'containers/MainStats'
import { useNearblock } from 'integrations/nearblocks'
import {
  EIconName,
  ESize,
  EState,
  EType,
  MbAction,
  MbButton,
  MbIcon,
  MbModal,
  MbText,
} from 'mintbase-ui'
import Link from 'next/link'
import { useContext, useState } from 'react'
import { StoresContext } from 'services/providers/StoresContext'
import { PATHS } from 'utils/constants'
import GetFeaturedModal from './GetFeaturedModal'

const heroStore = 'nearcon2.mintbase1.near'

const LandingHero = ({ header1, header2, subHeader }) => {
  const [showGetFeaturedModal, setShowGetFeaturedModal] = useState(false)
  const { selectedStore, storeIds } = useContext(StoresContext)

  const { getExplorerAccountLink } = useNearblock()

  const createUrl =
    selectedStore || storeIds[0]
      ? `/create/${selectedStore || storeIds[0]}`
      : `/create/null`

  return (
    <>
      <div className="flex flex-col">
        <div className="grid grid-cols-1 lg:grid-cols-2 hero">
          <div className="flex grow flex-col justify-center md:h-auto">
            <div className="mb-48 text-center lg:text-left">
              <div className="hero-anim">
                <MbText className="h2-130 dark:text-white leading-snug">
                  {header1}
                </MbText>
              </div>
              <div className="hero-anim">
                <MbText className="display-90 text-mb-red ">{header2}</MbText>
              </div>
              <div className="hero-anim lg:pr-48">
                <MbText className="h3-90 dark:text-gray-100 text-gray-700 dark:text-gray-300 mt-32 leading-snug xl:w-5/6">
                  {subHeader}
                </MbText>
              </div>
            </div>

            <div className="flex flex-wrap gap-12 md:gap-24 justify-center lg:justify-start mb-24 md:mb-32 hero-anim">
              <div>
                <Link passHref href={createUrl}>
                  <a>
                    <MbButton
                      btnType={EType.PRIMARY}
                      label="Launchpad"
                      size={ESize.BIG}
                      state={EState.ACTIVE}
                    />
                  </a>
                </Link>
              </div>
              <div>
                <Link passHref href={PATHS.docGettingStarted}>
                  <a target="_blank" rel="noreferrer">
                    <MbButton
                      btnType={EType.PRIMARY}
                      label="Develop"
                      size={ESize.BIG}
                      state={EState.ACTIVE}
                    />
                  </a>
                </Link>
              </div>
              <div>
                <Link passHref href="/market">
                  <a>
                    <MbButton
                      btnType={EType.SECONDARY}
                      label="Explore"
                      size={ESize.BIG}
                      state={EState.ACTIVE}
                    />
                  </a>
                </Link>
              </div>
            </div>
            <div className="text-center lg:text-left lg:-ml-3 mb-48 lg:mb-0 hero-anim">
              <ExternalButton
                text="Submit Grant Application"
                url={PATHS.grants}
              />
            </div>
          </div>
          <div className="hero-anim">
            <StoreCard id={heroStore} isBigStoreCard />
            <div className="grid grid-cols-2 lg:flex items-center gap-12 justify-center lg:justify-end mt-12">
              <div className="text-right">
                <ExternalButton
                  text="Contract"
                  url={getExplorerAccountLink(heroStore)}
                />
              </div>

              <div>
                <MbAction
                  size={ESize.MEDIUM}
                  type="button"
                  onClick={() => setShowGetFeaturedModal(true)}
                >
                  <div className="flex items-center gap-4">
                    <span className="whitespace-nowrap">Get Featured</span>
                    <MbIcon
                      color={'blue-300'}
                      name={EIconName.INFO}
                      size="18px"
                      darkColor="blue-100"
                      className="m-4"
                    />
                  </div>
                </MbAction>
              </div>
            </div>
          </div>
        </div>
        <div className="hero-anim">
          <MainStats />
        </div>
      </div>
      <MbModal
        topTitle="Get Featured"
        onClose={() => setShowGetFeaturedModal(false)}
        open={showGetFeaturedModal}
      >
        <GetFeaturedModal />
      </MbModal>
    </>
  )
}

export default LandingHero
