import { useEffect, useState } from 'react'
import useWindowSize from './useWindowSize'

export const useItemsPerPage = ({
  smallScreenItems,
  bigScreenItems,
}: {
  smallScreenItems: number
  bigScreenItems: number
}) => {
  const [itemsPerPage, setItemsPerPage] = useState(smallScreenItems)

  const { width } = useWindowSize()

  useEffect(() => {
    setItemsPerPage(width < 1920 ? smallScreenItems : bigScreenItems)
  }, [width])

  return {
    itemsPerPage,
  }
}
