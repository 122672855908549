import React from 'react'
import LandingPage from 'containers/LandingPage'
import { GetServerSidePropsContext } from 'next'
import { parse } from 'cookie'

const Home = () => <LandingPage />

export default Home

export const getServerSideProps = ({ req }: GetServerSidePropsContext) => {
  const {host} = req.headers
  const hasTrackingDisabled = (req.headers.cookie > '')
     ? parse(req.headers.cookie).gdprConsent === 'false'
     : false

  return {
    props: {
      host,
      trackingConsented: !hasTrackingDisabled,
    },
  }
}
