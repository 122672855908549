import React, { useCallback, useRef } from 'react'
import { MbInfoCard } from 'mintbase-ui'
import { useStats } from './MainStats.controller'

export const MainStats = ({ className = '' }) => {
  const { overallStats, loading } = useStats()
  return (
    <div className="mb-64 mt-32 lg:mt-0">
      <div className="grid grid-cols-2 md:grid-cols-5 gap-12 lg:gap-24">
        {overallStats.map(({ stat, name }) => (
          <MbInfoCard
            key={name}
            boxInfo={{
              description: stat,
              title: name,
              isNumber: true,
              loading,
            }}
          />
        ))}
      </div>
    </div>
  )
}
