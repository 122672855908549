import { useQuery } from '@apollo/client'
import CenteredHeader from 'components/CenteredHeader/CenteredHeader'
import { Container } from 'components/Containers'
import Grid from 'components/Grid'
import LandingHero from 'components/LandingHero/LandingHero'
import ThingCard from 'components/ThingCard'
import {
  BIG_LANDING_ITEMS_PER_PAGE,
  SMALL_LANDING_ITEMS_PER_PAGE,
} from 'constants/pagination'
import { LatestListQuery } from 'containers/Marketplace/types/Marketplace.type'
import { GET_LATEST_LIST } from 'containers/Marketplace/utils/Marketplace.graphql'
import TopStores from 'containers/TopStores'
import { useItemsPerPage } from 'hooks/useItemsPerPage'
import ContentCards from '../../components/ContentCards'
import {
  buildingOnNearContent,
  featuredBuildingOnMintbaseContent,
  landingHero,
} from './LandingPage.constants'

const LatestListings = () => {
  const { itemsPerPage } = useItemsPerPage({
    smallScreenItems: SMALL_LANDING_ITEMS_PER_PAGE,
    bigScreenItems: BIG_LANDING_ITEMS_PER_PAGE,
  })

  const { loading, data } = useQuery<LatestListQuery>(GET_LATEST_LIST, {
    variables: {
      limit: itemsPerPage,
      offset: 0,
      orderBy: { createdAt: 'desc' },
    },
  })

  const things =
    loading || !data
      ? []
      : data.markets.map((item) => ({
          thingId: item.thing.id,
          title: item.thing.metadata?.title ?? '',
          coverImage: item.thing.metadata?.media ?? null,
        }))

  return (
    <div className="grid mt-64">
      <CenteredHeader
        header="Latest Listings"
        subHeader="Newest listings popping up every 5 minutes"
      />
      <div className="mt-32">
        <Grid>
          {things?.map((thing, index) => (
            <ThingCard
              key={index}
              id={thing.thingId}
              title={thing.title}
              coverImage={thing.coverImage}
            />
          ))}
        </Grid>
      </div>
    </div>
  )
}

const Home = () => {
  return (
    <Container className="small-container">
      <LandingHero {...landingHero} />
      <TopStores />
      <LatestListings />
      <ContentCards {...featuredBuildingOnMintbaseContent} />
      <ContentCards {...buildingOnNearContent} />
    </Container>
  )
}

export default Home
