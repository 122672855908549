import { useContext, useEffect, useState } from 'react'
import { firebaseCollections, getFirebaseCollection } from 'services/firebase'
import { ThemeContext, ThemeContextType } from 'services/providers/ThemeContext'
import { getCurrentTheme } from 'utils/currentTheme'

interface StoreInfoProps {
  id: string
  avatar?: string
}

export const storeEmptyImage = {
  dark: '/images/store-dark.png',
  light: '/images/store-light.png',
}

const useStoreImageController = ({ id, avatar }: StoreInfoProps) => {
  const { theme }: ThemeContextType = useContext(ThemeContext)
  const [storeAvatar, setStoreAvatar] = useState(null)
  const [storeHeader, setStoreHeader] = useState(null)
  const [storeTitle, setStoreTitle] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  const getStoreDocument = async () => {
    const snapshot = await getFirebaseCollection(firebaseCollections.STORE)
      .where('contract', '==', id)
      .get()

    const shortStoreId = id.split('.').shift()

    if (snapshot.empty) {
      setStoreTitle(shortStoreId)
      setStoreAvatar(storeEmptyImage[getCurrentTheme(theme)])
      setStoreHeader(storeEmptyImage[getCurrentTheme(theme)])
      setIsLoading(false)
      return
    }

    const [storeDoc] = snapshot.docs

    const { storeImage, headerImage, displayName } = storeDoc.data()

    const hasStoreImage = Boolean(storeImage)
    const hasHeaderImage = Boolean(headerImage)

    setStoreTitle(displayName || shortStoreId)
    setStoreAvatar(
      hasStoreImage ? storeImage : storeEmptyImage[getCurrentTheme(theme)]
    )
    setStoreHeader(
      hasHeaderImage ? headerImage : storeEmptyImage[getCurrentTheme(theme)]
    )
    setIsLoading(false)
  }

  useEffect(() => {
    if (avatar) return
    getStoreDocument()
  }, [theme])

  return {
    storeAvatar,
    storeHeader,
    storeTitle,
    isLoading,
  }
}

export { useStoreImageController }
