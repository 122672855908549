import ExternalButton from 'components/ExternalButton'
import { MbText, MbAction, MbIcon, EIconName } from 'mintbase-ui'
import { useState } from 'react'

const GetFeaturedModal = () => {
  const [showLinkCopiedText, setShowLinkCopiedText] = useState(false)

  const handleCopyLink = async () => {
    await navigator.clipboard.writeText('#MintbaseStore')
    setShowLinkCopiedText(true)
    setTimeout(() => setShowLinkCopiedText(false), 3000)
  }
  return (
    <>
      <div className="p-24 flex w-full flex-col gap-24">
        <MbText className="p-big-90">
          Publish the link to your store in Twitter or Instagram
        </MbText>
        <MbText className="p-big-90">
          Tag <span className="text-mb-red">@Mintbase</span> and add{' '}
          <span className="text-mb-red">#MintbaseStore</span> to your post.
        </MbText>
        <MbText className="p-big-90">
          That&apos;s it! We&apos;ll periodically review and update the featured
          store.
        </MbText>
      </div>
      <div className="flex flex-wrap justify-center my-24 gap-12 md:gap-24 px-24">
        <ExternalButton text="Twitter" url={`https://twitter.com/mintbase`} />
        <ExternalButton
          text="Instagram"
          url={`https://www.instagram.com/mintbase_/`}
        />
        <div className="text-center">
          <MbAction onClick={handleCopyLink}>
            <div className="flex items-center gap-4">
              <span>Copy Hashtag</span>
              <MbIcon
                color={'blue-300'}
                name={EIconName.COPY}
                size="16px"
                darkColor="blue-100"
                className="m-4"
              />
            </div>
          </MbAction>
        </div>
      </div>
      {showLinkCopiedText && (
        <MbText className="p-big-90 mb-24 text-center">
          Hashtag copied to clipboard
        </MbText>
      )}
    </>
  )
}

export default GetFeaturedModal
