import Image from 'next/image'
import { MbText, MbButton, EType, ESize, EState } from 'mintbase-ui'
import Link from 'next/link'

export interface ContentCardType {
  imgPath: string
  buttonUrl: string
  buttonText: string
  title: string
  copy: string
  backgroundColor: string
}
const ContentCard = ({
  imgPath,
  buttonText,
  buttonUrl,
  title,
  copy,
  backgroundColor,
}: ContentCardType) => {
  return (
    <div
      className={
        'rounded overflow-hidden bg-white dark:bg-gray-850 text-center dark:text-gray-300'
      }
    >
      <div className={backgroundColor}>
        <Image
          src={imgPath}
          alt={title}
          width={500}
          height={202}
          layout="intrinsic"
          className="rounded"
        />
      </div>

      <MbText className="p-sub-130 mt-24">{title}</MbText>
      <MbText className="p-big-90 m-24 mr-48 ml-48 dark:text-gray-200 text-gray-700">
        {copy}
      </MbText>
      <div className="m-24">
        <Link passHref href={buttonUrl}>
          <a target="_blank" rel="noreferrer">
            <MbButton
              btnType={EType.SECONDARY}
              label={buttonText}
              size={ESize.MEDIUM}
              state={EState.ACTIVE}
            />
          </a>
        </Link>
      </div>
    </div>
  )
}

export default ContentCard
