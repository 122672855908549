import { gql } from 'apollo-boost'

export const GET_MAIN_STATS = gql`
  query getEverything @cached {
    stores_aggregate {
      aggregate {
        count
      }
    }
    things_aggregate {
      aggregate {
        count
      }
    }
    tokens_aggregate {
      aggregate {
        count
      }
    }
    minters_aggregate {
      aggregate {
        count
      }
    }
    lists_aggregate {
      aggregate {
        count
      }
    }
    offers_aggregate(where: { acceptedAt: { _is_null: false } }) {
      aggregate {
        sum {
          price
        }
      }
    }
  }
`
