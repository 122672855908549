import { useState, useEffect } from 'react'
import { firebaseCollections, getFirebaseCollection } from 'services/firebase'
import { ESmallCardType, MbSmallCard } from 'mintbase-ui'
import Link from 'next/link'
import { useStoreImageController } from 'containers/UserImage/controllers/StoreImage.controller'

interface StoreCardProps {
  id: string
  title?: string
  avatar?: string
  isBigStoreCard?: boolean
  link?: string
}

const StoreCard = ({
  id,
  avatar,
  title,
  isBigStoreCard,
  link,
}: StoreCardProps) => {
  const { storeHeader, isLoading, storeTitle } = useStoreImageController({
    id,
    avatar,
  })

  const card = {
    centerElement: (
      <img className="h-full w-full object-cover" src={storeHeader} />
    ),
    title: title ?? storeTitle,
  }

  return (
    <Link passHref href={link || `/store/${id}`}>
      <a>
        <MbSmallCard
          card={card}
          cardType={ESmallCardType.STORE}
          loading={isLoading}
          isBigStoreCard={isBigStoreCard}
        />
      </a>
    </Link>
  )
}

export default StoreCard
