import { getNetworkDetailsNoSSR } from 'lib/networkDetails'
import { useEffect, useState } from 'react'

// works for both transactions hashes and receipt ids

const useNearblock = () => {
  const [accountUrl, setAccountUrl] = useState('')
  const [hashUrl, setHashUrl] = useState('')
  const [transactionUrl, setTransactionUrl] = useState('')

  useEffect(() => {
    const { networkName } = getNetworkDetailsNoSSR()

    const sub = networkName === 'testnet' ? 'testnet.' : ''

    setAccountUrl(`https://${sub}nearblocks.io/address/`)
    setHashUrl(`https://${sub}nearblocks.io/hash/`)
    setTransactionUrl(`https://${sub}nearblocks.io/txns/`)
  }, [])

  const getExplorerHashLink = (hash: string) => {
    const { networkName } = getNetworkDetailsNoSSR()

    const sub = networkName === 'testnet' ? 'testnet.' : ''

    return `${hashUrl}${hash}`
  }

  const getExplorerTransactionLink = (hash: string) => {
    const { networkName } = getNetworkDetailsNoSSR()

    const sub = networkName === 'testnet' ? 'testnet.' : ''

    return `${transactionUrl}${hash}`
  }

  const getExplorerAccountLink = (accountId: string) => {
    const { networkName } = getNetworkDetailsNoSSR()
    const sub = networkName === 'testnet' ? 'testnet.' : ''

    return `${accountUrl}${accountId}`
  }

  return {
    accountUrl,
    hashUrl,
    transactionUrl,
    getExplorerAccountLink,
    getExplorerHashLink,
    getExplorerTransactionLink,
  }
}

export { useNearblock }
