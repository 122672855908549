export const landingHero = {
  header1: 'Fast, Scalable, & Decentralized',
  header2: 'NFT Infrastructure.',
  subHeader:
    'Build your own minter, market, or redeemer today using our developer toolkits on NEAR: A sharded and green blockchain.',
}

export const buildingOnNearContent = {
  header: 'Powered By NEAR Protocol',
  subHeader: 'The future is sharded, WASM chains.',
  buttonText: 'More on NEAR',
  buttonUrl: 'https://near.org',
  cards: [
    {
      title: 'Sharded WASM Chain',
      copy: 'Developers can use evolving loved languages like rust to deploy smart contracts on single event bus sharded chain (Everyone does not have to party on the same shard like Optimism).',
      imgPath: '/images/graphic-mintbase-up.png',
      buttonUrl:
        'https://near.org/blog/near-launches-simple-nightshade-the-first-step-towards-a-sharded-blockchain/',
      buttonText: 'More on Sharding',

      backgroundColor: 'bg-gray-150 dark:bg-blue-100-15',
    },
    {
      title: 'Fast & Cheap Transactions',
      copy: 'One second block times gives us 100k TPS using 4 shards. Transactions like minting and listing average around $0.01-$0.9. Really neat when all foundations are set for full decentralization. ',
      imgPath: '/images/graphic-mintbase-triangle.png',
      buttonUrl: 'https://nearblocks.io/',
      buttonText: 'See Explorer',
      backgroundColor: 'bg-gray-150 dark:bg-blue-100-15',
    },
    {
      title: 'Green Proof of Stake',
      copy: 'As a proof of stake chain from the start, NEAR Protocol was also awarded the climate neutral product label.',
      imgPath: '/images/graphic-mintbase-orbit.png',
      buttonUrl: 'https://near.org/blog/near-climate-neutral-product/',
      buttonText: 'Learn More',
      backgroundColor: 'bg-gray-150 dark:bg-blue-100-15',
    },
  ],
}

export const featuredCards = [
  {
    imgPath: '/images/gorilla-logo-mintbase.png',
    buttonUrl:
      'https://medium.com/mintbase/store-s-of-the-week-gorilla-shops-cb0f07bd9332',
    buttonText: 'Visit',
    title: 'Better Storefronts',
    copy: 'Using a few clicks you can deploy youre-commerce and mint thousands NFTs using Mintbase API as a backbone.',
    backgroundColor: 'bg-blue-100-15',
  },
  {
    imgPath: '/images/3xr-logo-mintbase.png',

    title: 'Automatic Metaverse Gallery',
    copy: 'Every store on Mintbase has their own virtual gallery set up automatically on Minting.',
    buttonUrl:
      'https://blog.mintbase.io/3xr-is-building-virtual-spaces-for-your-nfts-15f4bd1ea87f',
    buttonText: 'Visit',
    backgroundColor: 'bg-black',
  },
  {
    imgPath: '/images/astrodao-logo-mintbase.png',
    buttonUrl: 'https://blog.mintbase.io/cfcs-mintbase-astrodao-4c01b47bfa4c',
    buttonText: 'Visit',
    title: 'Mint from DAOs',
    copy: 'Mint NFTs with community consensus.',
    backgroundColor: 'bg-mb-red',
  },
]
export const featuredBuildingOnMintbaseContent = {
  header: 'Building on Mintbase Protocol',
  subHeader: 'Using TypeScript, GraphQL, and all things you are used to.',
  buttonText: 'View Others',
  buttonUrl: '/builders',
  cards: featuredCards,
}

export const buildingOnMintbaseContent = {
  header: 'Building on Mintbase Protocol',
  subHeader: 'Using TypeScript, GraphQL, and all things you are used to.',
  buttonText: 'Develop on Mintbase',
  buttonUrl: 'https://docs.mintbase.io/dev/getting-started',
  cards: [
    ...featuredCards,

    {
      imgPath: '/partners/mintickt-logo-mintbase.png',
      buttonUrl:
        'https://blog.mintbase.io/web3-tools-for-creators-a-conversation-with-minting-music-4b0c080b778a',
      buttonText: 'Visit',
      title: 'Better Storefronts',
      copy: 'Using a few clicks you can deploy youre-commerce and mint thousands NFTs using Mintbase API as a backbone.',
      backgroundColor: 'bg-blue-100-15',
    },
    {
      imgPath: '/partners/amber-logo-mintbase.png',
      buttonUrl:
        'https://blog.mintbase.io/web3-tools-for-creators-a-conversation-with-minting-music-4b0c080b778a',
      buttonText: 'Visit',
      title: 'Better Storefronts',
      copy: 'Using a few clicks you can deploy youre-commerce and mint thousands NFTs using Mintbase API as a backbone.',
      backgroundColor: 'bg-blue-100-15',
    },
    {
      imgPath: '/partners/explorians-logo-mintbase.png',
      title: 'AR NFT Hunt',
      copy: 'Augmented Reality with NFTs.',
      buttonUrl:
        'https://blog.mintbase.io/3xr-is-building-virtual-spaces-for-your-nfts-15f4bd1ea87f',
      buttonText: 'Visit',
      backgroundColor: 'bg-black',
    },
    {
      imgPath: '/partners/swaggar-logo-mintbase.png',
      buttonUrl: 'https://www.swaggerdao.xyz/',
      buttonText: 'Visit',
      title: 'Physical Redeeming Things',
      copy: 'Mint NFTs for real deal things.',
      backgroundColor: 'bg-gray-100',
    },
    {
      imgPath: '/partners/arroz-logo-mintbase.png',
      buttonUrl: 'https://arrozestudios.pt/',
      buttonText: 'Visit',
      title: 'Lisbon Collective Accepting NEAR',
      copy: 'Local spot to check in NFT tickets and buy a beer with NEAR.',
      backgroundColor: 'bg-blue-100-15',
    },
    {
      imgPath: '/partners/mintingmusic-logo-mintbase.png',
      buttonUrl:
        'https://blog.mintbase.io/web3-tools-for-creators-a-conversation-with-minting-music-4b0c080b778a',
      buttonText: 'Visit',
      title: 'Standalone Music Shops',
      copy: 'Build you own UI and pull in the files types you need.',
      backgroundColor: 'bg-gray-50',
    },
  ],
}
